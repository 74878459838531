import React, { FC, useEffect, useState } from 'react';
import styles from './index.module.scss';
import amplification from './assets/amplification.png';
import htt1 from './assets/HTT 18F/Htt1.jpg';
import htt2 from './assets/HTT 18F/Htt2.jpg';
import htt3 from './assets/HTT 18F/Htt3.jpg';
import htt4 from './assets/HTT 18F/Htt4.jpg';
import htt5 from './assets/HTT 18F/Htt5.jpg';
import htt6 from './assets/HTT 18F/Htt6.jpeg';
import ve1 from './assets/VICTORIAN ERA/ve1.jpg';
import ve2 from './assets/VICTORIAN ERA/ve2.jpg';
import ve3 from './assets/VICTORIAN ERA/ve3.jpg';
import ve4 from './assets/VICTORIAN ERA/ve4.jpg';
import ve5 from './assets/VICTORIAN ERA/ve5.jpg';
import ve7 from './assets/VICTORIAN ERA/ve7.jpg';
import ve8 from './assets/VICTORIAN ERA/ve8.jpg';
import ve9 from './assets/VICTORIAN ERA/ve9.jpg';
import ve10 from './assets/VICTORIAN ERA/ve10.jpg';
import ve11 from './assets/VICTORIAN ERA/ve11.jpg';
import ve12 from './assets/VICTORIAN ERA/ve12.jpg';
import ve13 from './assets/VICTORIAN ERA/ve13.jpeg';
import yihe1 from './assets/一郎和牛燒肉/yihe1.jpg';
import yihe2 from './assets/一郎和牛燒肉/yihe2.jpg';
import yihe3 from './assets/一郎和牛燒肉/yihe3.jpg';
import yihe4 from './assets/一郎和牛燒肉/yihe4.jpg';
import yihe5 from './assets/一郎和牛燒肉/yihe5.jpg';
import yihe6 from './assets/一郎和牛燒肉/yihe6.jpg';
import yihe7 from './assets/一郎和牛燒肉/yihe7.jpg';
import yihe8 from './assets/一郎和牛燒肉/yihe8.jpg';
import yihe9 from './assets/一郎和牛燒肉/yihe9.jpg';
import yihe10 from './assets/一郎和牛燒肉/yihe10.jpg';
import yihe11 from './assets/一郎和牛燒肉/yihe11.jpg';
import yihe12 from './assets/一郎和牛燒肉/yihe12.jpg';
import yihe13 from './assets/一郎和牛燒肉/yihe13.jpg';
import panda1 from './assets/Panda Hotel/panda1.jpg';
import panda2 from './assets/Panda Hotel/panda2.jpg';
import panda3 from './assets/Panda Hotel/panda3.jpg';
import panda4 from './assets/Panda Hotel/panda4.jpg';
import panda5 from './assets/Panda Hotel/panda5.jpg';
import panda6 from './assets/Panda Hotel/panda6.jpg';
import df1 from './assets/Shenzhen Duty Free (HK) Co Ltd/dutyFree1.jpg';
import df2 from './assets/Shenzhen Duty Free (HK) Co Ltd/dutyFree2.jpg';
import df3 from './assets/Shenzhen Duty Free (HK) Co Ltd/dutyFree3.jpg';
import df4 from './assets/Shenzhen Duty Free (HK) Co Ltd/dutyFree4.jpg';
import df5 from './assets/Shenzhen Duty Free (HK) Co Ltd/dutyFree5.jpg';
import df6 from './assets/Shenzhen Duty Free (HK) Co Ltd/dutyFree6.jpeg';
import df7 from './assets/Shenzhen Duty Free (HK) Co Ltd/dutyFree7.jpeg';
import sp1 from './assets/Swatch1/sp1.jpg';
import sp2 from './assets/Swatch1/sp2.jpg';
import sp3 from './assets/Swatch1/sp3.jpg';
import sp4 from './assets/Swatch1/sp4.jpg';
import sp5 from './assets/Swatch1/sp5.jpg';
import sp6 from './assets/Swatch1/sp6.jpg';
import sp7 from './assets/Swatch1/sp7.jpg';
import sp8 from './assets/Swatch1/sp8.jpg';
import sp9 from './assets/Swatch1/sp9.jpg';
import sp10 from './assets/Swatch1/sp10.jpg';
import sp11 from './assets/Swatch1/sp11.jpg';
import sp12 from './assets/Swatch1/sp12.jpg';
import DHL1 from './assets/DHL - 7A/DHL1.jpg';
import DHL2 from './assets/DHL - 7A/DHL2.jpg';
import DHL3 from './assets/DHL - 7A/DHL3.jpg';
import DHL4 from './assets/DHL - 7A/DHL4.jpg';
import DHL5 from './assets/DHL - 7A/DHL5.jpg';
import DHL6 from './assets/DHL - 7A/DHL6.jpg';
import CSL1 from './assets/CSL-BCC Core E/CSL1.jpg';
import CSL2 from './assets/CSL-BCC Core E/CSL2.jpg';
import CSL3 from './assets/CSL-BCC Core E/CSL3.jpg';
import CSL4 from './assets/CSL-BCC Core E/CSL4.jpg';
import CSL5 from './assets/CSL-BCC Core E/CSL5.jpg';
import CSL6 from './assets/CSL-BCC Core E/CSL6.jpg';
import CSL7 from './assets/CSL-BCC Core E/CSL7.jpg';
import Hutchison1 from './assets/Hutchison LG-3/Hutchison1.jpg';
import Hutchison2 from './assets/Hutchison LG-3/Hutchison2.jpg';
import Hutchison3 from './assets/Hutchison LG-3/Hutchison3.jpg';
import Hutchison4 from './assets/Hutchison LG-3/Hutchison4.jpg';
import Hutchison5 from './assets/Hutchison LG-3/Hutchison5.jpg';
import ICC1 from './assets/ICC/ICC1.jpg';
import ICC2 from './assets/ICC/ICC2.jpg';
import ICC3 from './assets/ICC/ICC3.jpg';
import ICC4 from './assets/ICC/ICC4.jpg';
import ICC5 from './assets/ICC/ICC5.jpg';
import ICC6 from './assets/ICC/ICC6.jpg';
import CCB1 from './assets/CCB - Tai Po Branch/CCB1.jpg';
import CCB2 from './assets/CCB - Tai Po Branch/CCB2.jpg';
import CCB3 from './assets/CCB - Tai Po Branch/CCB3.jpg';
import CCB4 from './assets/CCB - Tai Po Branch/CCB4.jpg';
import CCB5 from './assets/CCB - Tai Po Branch/CCB5.jpg';
import CCB6 from './assets/CCB - Tai Po Branch/CCB6.jpg';
import AZ1 from './assets/AZ Electronic Materials/AZ1.jpg';
import AZ2 from './assets/AZ Electronic Materials/AZ2.jpg';
import AZ3 from './assets/AZ Electronic Materials/AZ3.jpg';
import AZ4 from './assets/AZ Electronic Materials/AZ4.jpg';
import AZ5 from './assets/AZ Electronic Materials/AZ5.jpg';
import AZ6 from './assets/AZ Electronic Materials/AZ6.jpg';
import AZ7 from './assets/AZ Electronic Materials/AZ7.jpg';
import AZ8 from './assets/AZ Electronic Materials/AZ8.jpg';
import Horizon1 from './assets/Horizon/Horizon1.jpg';
import Horizon2 from './assets/Horizon/Horizon2.jpg';
import Horizon3 from './assets/Horizon/Horizon3.jpg';
import Horizon4 from './assets/Horizon/Horizon4.jpg';
import Horizon5 from './assets/Horizon/Horizon5.jpg';
import Horizon6 from './assets/Horizon/Horizon6.jpg';
import AXA1 from './assets/AXA/AXA1.jpg';
import AXA2 from './assets/AXA/AXA2.jpg';
import AXA3 from './assets/AXA/AXA3.jpg';
import AXA4 from './assets/AXA/AXA4.jpg';
import Black1 from './assets/Black Rock/Black1.jpg';
import Black2 from './assets/Black Rock/Black2.jpg';
import Black3 from './assets/Black Rock/Black3.jpg';
import Black4 from './assets/Black Rock/Black4.jpg';
import Black5 from './assets/Black Rock/Black5.jpg';
import Black6 from './assets/Black Rock/Black6.jpg';
import razer1 from './assets/Razer/razer1.jpeg';
import razer2 from './assets/Razer/razer2.jpeg';
import razer3 from './assets/Razer/razer3.jpeg';
import razer4 from './assets/Razer/razer4.jpeg';
import razer5 from './assets/Razer/razer5.jpeg';
import razer6 from './assets/Razer/razer6.jpeg';
import ttb1 from './assets/ttb/ttb (1).jpg';
import ttb2 from './assets/ttb/ttb (2).jpg';
import ttb3 from './assets/ttb/ttb (3).jpg';
import ttb4 from './assets/ttb/ttb (4).jpg';
import ttb5 from './assets/ttb/ttb (5).jpg';
import ttb6 from './assets/ttb/ttb (6).jpg';
import ttb7 from './assets/ttb/ttb (7).jpg';
import nc1 from './assets/newCity/newCity (1).jpg';
import nc2 from './assets/newCity/newCity (8).jpg';
import nc3 from './assets/newCity/newCity (3).jpg';
import nc4 from './assets/newCity/newCity (4).jpg';
import nc5 from './assets/newCity/newCity (5).jpg';
import nc6 from './assets/newCity/newCity (6).jpg';
import nc7 from './assets/newCity/newCity (7).jpg';
import bc1 from './assets/bc/bc (1).jpg';
import bc2 from './assets/bc/bc (2).jpg';
import bc3 from './assets/bc/bc (3).jpg';
import bc4 from './assets/bc/bc (4).jpg';
import bc5 from './assets/bc/bc (5).jpg';
import bc6 from './assets/bc/bc (6).jpg';
import bc7 from './assets/bc/bc (7).jpg';
import tes1 from './assets/tes/tes (1).jpeg';
import tes2 from './assets/tes/tes (2).jpeg';
import tes3 from './assets/tes/tes (3).jpeg';
import tes4 from './assets/tes/tes (4).jpeg';
import tes5 from './assets/tes/tes (5).jpeg';
import tes6 from './assets/tes/tes (6).jpeg';
import tes7 from './assets/tes/tes (7).jpeg';
import tes8 from './assets/tes/tes (8).jpeg';
import boat1 from './assets/boat/boat (1).jpeg';
import boat2 from './assets/boat/boat (2).jpeg';
import boat3 from './assets/boat/boat (3).jpeg';
import qr1 from './assets/queenRoad/1.jpg';
import qr2 from './assets/queenRoad/2.jpg';
import qr3 from './assets/queenRoad/3.jpg';
import qr4 from './assets/queenRoad/4.jpg';
import hqBank1 from './assets/Hauqi/1.jpg';
import hqBank2 from './assets/Hauqi/2.jpg';
import hqBank3 from './assets/Hauqi/3.jpg';
import hqBank4 from './assets/Hauqi/4.jpg';

import { DisplayPhotos } from './components/DisplayPhotos';
import goUp from '../aboutUs/assets/goup.png';
import { BackTop, Typography } from 'antd';
import intl from 'react-intl-universal';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import logo from '../contactUs/assets/logo.png';
import Carousel, { Modal as ImagesModal, ModalGateway } from 'react-images';

export interface DisplayItem {
  photos: string[];
  titles: string;
}

const RenovationDesign: FC = () => {
  const [photosIndex, setPhotosIndex] = useState(-1);
  const [showPhotos, setShowPhotos] = useState(false);
  const [screenSize, setScreenSize] = useState<number>(document.documentElement.clientWidth);
  const { Text } = Typography;
  const resize = async () => {
    const offsetWid = document.documentElement.clientWidth;
    setScreenSize(offsetWid);
  };
  window.addEventListener('resize', debounce(resize, 20));
  const history = useHistory();

  const displaySets: DisplayItem[] = [
    {
      photos: [df7, df2, df3, df1, df4, df5, df6],
      titles: intl.get('SDFL'),
    },
    {
      photos: [Black2, Black1, Black3, Black4, Black5, Black6],
      titles: intl.get('BRL'),
    },
    {
      photos: [tes4, tes2, tes3, tes1, tes5, tes6, tes7, tes8],
      titles: intl.get('Atest'),
    },
    {
      photos: [ve13, ve1, ve2, ve3, ve4, ve5, ve7, ve8, ve9, ve10, ve11, ve12],
      titles: intl.get('HKNC'),
    },
    {
      photos: [CCB1, CCB2, CCB3, CCB4, CCB5, CCB6],
      titles: intl.get('CB'),
    },
    {
      photos: [Horizon6, Horizon2, Horizon3, Horizon4, Horizon5, Horizon1],
      titles: intl.get('HAL'),
    },
    {
      photos: [
        yihe13,
        yihe1,
        yihe2,
        yihe3,
        yihe4,
        yihe5,
        yihe6,
        yihe7,
        yihe8,
        yihe9,
        yihe10,
        yihe11,
        yihe12,
      ],
      titles: intl.get('Sky'),
    },

    {
      photos: [htt4, htt2, htt3, htt1, htt5, htt6],
      titles: intl.get('HTCL'),
    },
    {
      photos: [panda1, panda2, panda3, panda4, panda5, panda6],
      titles: intl.get('PandaHotel'),
    },

    {
      photos: [Hutchison1, Hutchison2, Hutchison3, Hutchison4, Hutchison5],
      titles: intl.get('HGCL'),
    },

    {
      photos: [AZ8, AZ2, AZ3, AZ4, AZ5, AZ6, AZ7, AZ1],
      titles: intl.get('AZ'),
    },

    {
      photos: [ICC5, ICC2, ICC3, ICC4, ICC1, ICC6],
      titles: intl.get('ICC'),
    },

    {
      photos: [sp1, sp2, sp3, sp4, sp5, sp6],
      titles: intl.get('TSGL'),
    },
    {
      photos: [sp7, sp8, sp9, sp10, sp11, sp12],
      titles: intl.get('TSG'),
    },
    {
      photos: [DHL1, DHL2, DHL3, DHL4, DHL5, DHL6],
      titles: intl.get('DHL'),
    },
    {
      photos: [CSL1, CSL2, CSL3, CSL4, CSL5, CSL6, CSL7],
      titles: intl.get('CSL'),
    },
    {
      photos: [AXA1, AXA2, AXA3, AXA4],
      titles: intl.get('AXA'),
    },
    {
      photos: [razer1, razer2, razer3, razer4, razer5, razer6],
      titles: intl.get('RS'),
    },
    {
      photos: [ttb7, ttb2, ttb3, ttb4, ttb5, ttb6, ttb1],
      titles: intl.get('TTB'),
    },
    {
      photos: [nc3, nc4, nc2, nc1, nc5, nc6, nc7],
      titles: intl.get('DPCS'),
    },
    {
      photos: [bc1, bc2, bc3, bc4, bc5, bc6, bc7],
      titles: intl.get('EPL'),
    },
    {
      photos: [hqBank1, hqBank2, hqBank3, hqBank4],
      titles: intl.get('zhonghuan'),
    },
    {
      photos: [qr1, qr2, qr3, qr4],
      titles: intl.get('queen'),
    },
    {
      photos: [boat1, boat2, boat3],
      titles: intl.get('PSIL'),
    },
  ];

  useEffect(() => {
    if (!!location.hash) {
      const title = decodeURI(location.hash).split('#')[1];
      displaySets.map((item, index) => {
        if (title === item.titles) {
          setShowPhotos(true);
          setPhotosIndex(index);
        }
      });
    }
  }, [location]);

  useEffect(() => {
    // 获取不到重新更新路由
    if (intl.get('AZ').length === 0) {
      history.push('/main/renovation-design');
    }
  }, []);

  return (
    <>
      {screenSize > 800 ? (
        <div className={styles.renovation}>
          {showPhotos ? (
            <DisplayPhotos
              show={showPhotos}
              photos={displaySets[photosIndex]}
              setPhotos={setPhotosIndex}
              setShow={setShowPhotos}
            />
          ) : (
            <div className={styles.renovationFatherBox}>
              <div className={styles.renovationTitle}>
                <span className={styles.renovationWords}>{intl.get('interiorDesignCases')}</span>
              </div>
              <div className={styles.renovationCases}>
                {displaySets.map((displayItem, index) => (
                  <div
                    className={styles.caseBody}
                    key={index}
                    onClick={() => {
                      setPhotosIndex(index);
                      setShowPhotos(true);
                    }}
                  >
                    <div className={styles.caseDimBack}>
                      <>
                        <img src={displayItem.photos[0]} className={styles.caseImg} alt={''} />
                        <img src={amplification} className={styles.amplification} alt={''} />
                      </>
                    </div>
                    <div className={styles.caseBar}>
                      <Text
                        className={styles.barText}
                        ellipsis={{ tooltip: true }}
                        style={{ color: '#ffffff', fontWeight: 600 }}
                      >
                        {displayItem.titles}
                      </Text>
                      <span className={styles.noOfImg}>
                        {displayItem.photos.length}
                        {intl.get('pics')}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <BackTop visibilityHeight={200} className={styles.goUp}>
            <img alt="" src={goUp} />
          </BackTop>
        </div>
      ) : (
        <div className={styles.designMobileWrapper}>
          <div className={styles.designBackImg}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
                width: 200,
                marginLeft: '10%',
                paddingTop: 30,
                paddingBottom: 30,
              }}
            >
              <img src={logo} alt={''} />
              <h1 style={{ color: '#ffffff' }}>{intl.get('interiorDesignCases')}</h1>
            </div>
          </div>
          <div className={styles.renovationFatherBoxM}>
            {/*<div className={styles.renovationTitle}>*/}
            {/*  <span className={styles.renovationWords}>{intl.get('interiorDesignCases')}</span>*/}
            {/*</div>*/}
            <div className={styles.renovationCasesM} style={{ marginTop: 20 }}>
              {displaySets.map((displayItem, index) => (
                <div
                  className={styles.caseBodyM}
                  key={index}
                  onClick={() => {
                    setPhotosIndex(index);
                    setShowPhotos(true);
                  }}
                >
                  <div className={styles.caseDimBackM}>
                    <>
                      <img src={displayItem.photos[0]} className={styles.caseImgM} alt={''} />
                    </>
                  </div>
                  <div className={styles.caseBarM}>
                    <Text className={styles.barText} ellipsis={{ tooltip: true }}>
                      {displayItem.titles}
                    </Text>
                    <span className={styles.noOfImg}>
                      {displayItem.photos.length}
                      {intl.get('pics')}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <ModalGateway>
              {photosIndex > -1 ? (
                <ImagesModal onClose={() => setPhotosIndex(-1)}>
                  <Carousel
                    views={displaySets[photosIndex].photos.map((item: string) => ({
                      source: item,
                    }))}
                  />
                </ImagesModal>
              ) : null}
            </ModalGateway>
          </div>
        </div>
      )}
    </>
  );
};

export default RenovationDesign;

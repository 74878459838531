import React, { FC, useState } from 'react';
import { Carousel } from 'antd';
import banner1 from './assets/banner1.jpg';
import banner1Title from './assets/banner1Title.png';
import banner1Subtitle from './assets/banner1Subtitle.png';
import banner2 from './assets/banner2.jpg';
import banner2Title from './assets/banner2Title.png';
import banner2Subtitle from './assets/banner2Subtitle.png';
import banner3 from './assets/banner3.jpg';
import banner3Title from './assets/banner3Title.png';
import banner3SubM from './assets/banner3SubM.png';
import banner3TitleM from './assets/banner3TitleM.png';
import banner1M from './assets/banner1m.png';
import banner2M from './assets/banner2m.png';
import banner3M from './assets/banner3m.png';
import banner1TitleM from './assets/banner1TitleM.png';
import banner1SubM from './assets/banner1SubM.png';
import banner2SubM from './assets/banner2SubM.png';
import styles from './index.module.scss';
import { debounce } from 'lodash';
import { BasicAnchor } from './anchor';

import bmt1 from './assets/banner1TitleM.png';
import bmt2 from './assets/banner2TitleM.png';
import bmt3 from './assets/banner3TitleM.png';
import bmst1 from './assets/banner1SubM.png';
import bmst2 from './assets/banner2SubM.png';
import bmst3 from './assets/banner3SubM.png';

export const BasicHeader: FC = () => {
  const [isTop, setIsTop] = useState(false);
  const [current, setCurrent] = useState(0);
  const [screenSize, setScreenSize] = useState<number>(document.documentElement.clientWidth);
  const resize = async () => {
    const offsetWid = document.documentElement.clientWidth;
    setScreenSize(offsetWid);
  };
  window.addEventListener('resize', debounce(resize, 20));

  const scroll = async () => {
    const isBottom =
      document.documentElement.scrollTop + 10 >= document.documentElement.clientHeight;
    if (isTop !== isBottom) {
      await setIsTop(isBottom);
    }
  };

  window.addEventListener('scroll', debounce(scroll, 20));

  // 轮播图change
  const afterChange = (currentSlide: number) => {
    setCurrent(currentSlide);
  };

  return (
    <>
      {screenSize > 800 ? (
        <div className={styles.banner} id="banner">
          <Carousel pauseOnHover={false} autoplaySpeed={4000} afterChange={afterChange} autoplay>
            <div id="#about-us">
              <img src={banner1} alt="" className={styles.bannerImg} />
              <img
                src={banner1Title}
                alt=""
                style={{ opacity: current === 0 ? 1 : 0, width: '1152px' }}
                className={
                  current === 0
                    ? `${styles.bannerTitle} ${styles.bannerMainTitle}`
                    : styles.bannerTitle
                }
              />
              <img
                src={banner1Subtitle}
                alt=""
                className={
                  current === 0
                    ? `${styles.bannerSubtitle} ${styles.bannerSubTitleText}`
                    : styles.bannerSubtitle
                }
              />
            </div>
            <div>
              <img src={banner2} alt="" className={styles.bannerImg} />
              <img
                src={banner2Title}
                alt=""
                style={{ opacity: current === 1 ? 1 : 0 }}
                className={
                  current === 1
                    ? `${styles.bannerTitle} ${styles.bannerMainTitle}`
                    : styles.bannerTitle
                }
              />
              <img
                src={banner2Subtitle}
                alt=""
                className={
                  current === 1
                    ? `${styles.bannerSubtitle} ${styles.bannerSubTitleText}`
                    : styles.bannerSubtitle
                }
              />
            </div>
            <div>
              <img src={banner3} alt="" className={styles.bannerImg} />
              <img
                src={banner3Title}
                alt=""
                style={{ opacity: current === 2 ? 1 : 0 }}
                className={
                  current === 2
                    ? `${styles.bannerTitle} ${styles.bannerMainTitle}`
                    : styles.bannerTitle
                }
              />
              <img
                src={banner2Subtitle}
                alt=""
                className={
                  current === 2
                    ? `${styles.bannerSubtitle} ${styles.bannerSubTitleText}`
                    : styles.bannerSubtitle
                }
              />
            </div>
          </Carousel>
          <BasicAnchor isTop={isTop} />
        </div>
      ) : (
        <>
          <div className={styles.bannerM}>
            <Carousel pauseOnHover={false} autoplaySpeed={4000} afterChange={afterChange} autoplay>
              <div id="#about-us">
                <img src={banner1M} alt="" className={styles.bannerImgM} />
                <img
                  src={bmst1}
                  alt=""
                  style={{ opacity: current === 0 ? 1 : 0 }}
                  className={
                    current === 0
                      ? `${styles.bannerTitle} ${styles.bannerMainTitle}`
                      : styles.bannerTitle
                  }
                />
                <img
                  src={bmt1}
                  alt=""
                  style={{ width: '80%' }}
                  className={
                    current === 0
                      ? `${styles.bannerSubtitle} ${styles.bannerSubTitleText}`
                      : styles.bannerSubtitle
                  }
                />
              </div>
              <div>
                <img src={banner2M} alt="" className={styles.bannerImgM} />
                <img
                  src={bmst2}
                  alt=""
                  style={{ opacity: current === 1 ? 1 : 0 }}
                  className={
                    current === 1
                      ? `${styles.bannerTitle} ${styles.bannerMainTitle}`
                      : styles.bannerTitle
                  }
                />
                <img
                  src={bmt2}
                  style={{ width: '80%' }}
                  alt=""
                  className={
                    current === 1
                      ? `${styles.bannerSubtitle} ${styles.bannerSubTitleText}`
                      : styles.bannerSubtitle
                  }
                />
              </div>
              <div>
                <img src={banner3M} alt="" className={styles.bannerImgM} />
                <img
                  src={bmst3}
                  alt=""
                  style={{ opacity: current === 2 ? 1 : 0 }}
                  className={
                    current === 2
                      ? `${styles.bannerTitle} ${styles.bannerMainTitle}`
                      : styles.bannerTitle
                  }
                />
                <img
                  src={bmt3}
                  style={{ width: '80%' }}
                  alt=""
                  className={
                    current === 2
                      ? `${styles.bannerSubtitle} ${styles.bannerSubTitleText}`
                      : styles.bannerSubtitle
                  }
                />
              </div>
            </Carousel>
          </div>
        </>
      )}
    </>
  );
};

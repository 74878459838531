import React, { FC } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './marker';
export const SimpleMap: FC = () => {
  const defaultProps = {
    center: {
      lat: 22.2492,
      lng: 114.1649,
    },
    zoom: 17,
  };

  //22.2504977545169, 114.16468757915109

  return (
    // Important! Always set the container height explicitly

    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBiSZuDg2XUKwfWXf9ZONsI0Vo_k_Q3vhA' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <Marker lat={22.2492} lng={114.1649} name="My Marker" color="red" />
      </GoogleMapReact>
    </div>
  );
};

export default {
  SDFL: '深圳免稅金鐘多功能廳及辦公室',
  BRL: '貝萊德金鐘辦公室',
  HKNC: '香江花月火鍋店',
  CB: '中國建設銀行(亞洲)大埔地舖',
  HAL: '中環國際金融中心辦公室',
  Sky: '一郎和牛燒肉',
  HTCL: '和記電訊青衣多功能廳',
  swimmingPool: '',
  HGCL: '環球電訊黃竹坑辦公室',
  AZ: '上環辦公室',
  ICC: 'ICC香港國際總商會金鐘辦公室',
  TSG: '斯沃琪集團鰂魚涌辦公室',
  DHL: 'DHL 荃灣辦公室及倉庫',
  CSL: '香港電訊數碼港辦公室',
  AXA: '安盛金融灣仔辦公室',
  RS: '雷蛇銅鑼灣地舖',
  TTB: '中環辦公室',
  DPCS: '荃灣愉景新城育嬰室及辦公室',
  EPL: '理文商業中心鰂魚涌26樓及27樓廁所及走廊',
  PSIL: '香港仔遊艇會多功能廳',
  PandaHotel: '荃灣悅來酒店游泳池及更衣室',
  TSGL: '斯沃琪集團維修中心',
  Atest: '海港城 a.testoni 地鋪',
  zhonghuan: '中環花旗銀行大廈寫字樓',
  queen: '中環皇后大道中8號寫字樓',
  inputInfo: '請輸入',
  inputDate: '請選擇日期',
  commentHere: '請留言',
  confirm: '確認提交',
  confirmInfo: '請放心提交，我們將切實保護您的隱私',
  usBtInfo1: '輸入您的信息，我們將及時與您取得聯繫',
  usBtInfo2: '',
  submit: '提交',
  reset: '重置',
  cancel: '取消',
  confirmPopUp1: '確定要重置所有信息嗎？',
  confirmPopUp2: '此操作將會刪除所有已填寫信息',
  successMsg: '您已提交成功，工作人員會盡快與你聯繫！',
};

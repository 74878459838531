import { FC } from 'react';
import RenovationDesign from '../pages/renovationDesign';
import HotArticles from '../pages/hotArticles';
import { ContactUs } from '../pages/contactUs/contactUsModal';

export interface RouteMapInterface {
  name: string;
  path: string;
  breadcrumbName: string;
  component: FC;
}

export const routeMap: RouteMapInterface[] = [
  {
    name: '裝修設計',
    path: '/main/renovation-design',
    breadcrumbName: '/main/renovation-design',
    component: RenovationDesign,
  },
  {
    name: '推薦信',
    path: '/main/hot-articles',
    breadcrumbName: '/main/hot-articles',
    component: HotArticles,
  },
  {
    name: '聯繫我們',
    path: '/main/contact-us',
    breadcrumbName: '/main/contact-us',
    component: ContactUs,
  },
];

export default {
  HK: 'Shenzhen Duty Free (HK) Co. Ltd.',
  HKArea: 'Area：4305 sq.ft',
  HKDescription: 'Description：Lippo Centre, Admiralty, HK',
  HTL: 'Hutchison Telephone Co. Ltd.',
  HTLArea: 'Area：5,000 sq.ft',
  HTLDescription: 'Description： Multi-Purpose Room at 18/F HTT, Tsing Yi',
  NCL: 'Hong Kong New Catering Ltd.',
  NCLArea: 'Area：7,000 sq.ft',
  NCLDescription: 'Description：VICTORIAN ERA Restaurant at 2F Pak Shing Building, Jordan, HK',
  aboutUs: 'About Us',
  interiorDesign: 'Interior Projects',
  interiorDesignCases: 'Interior Projects',
  recommendationLetter: 'Reference Letters',
  viewBtn: 'view',
  contactUs: 'Contact Us',
  pics: 'pics',
  companyName: 'Interior Design & Construction Co. Ltd.',
  companyInfo1:
    'Interior Design & Construction Co. Ltd. is a contracting company dedicated to provide real and lasting benefits to owners and occupants. Our company has extensive experience in interior fitting out works, office churn & re-stacking project, retail shop, hotel project and also including all related electrical, mechanical and other building services. Our qualified professional project team works closely with key client decision-makers from initial system planning, design, detailing and construction up to the completion of the project. We have obtained the highest level (I, II, III) registered Minor Works license recognized by the Hong Kong Buildings Department. Identify with our clients’ needs, provide creative design and deliver within timescales and warranty. We offer a complete range Interior solution with a strong dedication to achieve excellence in workmanship and Customers’ satisfaction. We believe that our comprehensive services can satisfy all your needs.',
  companyInfo2: '',
  companyInfo3: '',
  since: 'Since 2005',
  found:
    ' Id&c was established in 2005. More than 16 years of experience plus our professional team, we believed to deliver the best services to all our clients.',
  contactInfo: 'Contact Info.',
  T: 'T：',
  F: 'F：',
  E: 'E：',
  address: ' Address',
  currentAdd: 'UNIT 2519，TOWER B， SOUTHMARK，11 YIP HING STREET，WONG CHUK HANG， HONG KONG',
  btn: 'eng',
  fax: 'Fax',
  email: 'Email',
  phone: 'Phone',
  addr: 'Address',
};

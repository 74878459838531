import React, { ReactElement, useState } from 'react';
import { Layout } from 'antd';
import styles from './index.module.scss';
import { BasicFooter } from './Footer';
import { BasicAnchor } from './anchor';
import { BasicHeader } from './Header';

export const OtherLayout = ({ children }: { children: ReactElement }) => {
  const [maskStyle, setMaskStyle] = useState(false);

  const mobileMask = (v: boolean) => {
    setMaskStyle(v);
  }

  return (
    <>
      {document.documentElement.clientWidth > 800 ? (
        <Layout className={styles.layout}>
          <div className={styles.content}>
            <BasicAnchor isTop />
            {children}
          </div>
          <BasicFooter />
        </Layout>
      ) : (
        <Layout className={maskStyle ? `${styles.layoutMobileMask} ${styles.layoutMobile}` : styles.layoutMobile}>
          <BasicAnchor isTop mobileMask={mobileMask} />
          <div className={maskStyle ? styles.layoutMobileInfo : styles.contentMobile}>
            {children}
            <BasicFooter />
          </div>
        </Layout>
      )}
    </>
  );
};

import React from 'react';
import styles from '../index.module.css';

const Marker = (props: any) => {
  const { color, name, id } = props;
  return (
    <>
      <div
        className={styles.pin}
        style={{ backgroundColor: color, cursor: 'pointer' }}
        title={name}
      />
    </>
  );
};

export default Marker;

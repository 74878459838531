import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, message, Radio, DatePicker } from 'antd';
import styles from './index.module.scss';
import exclamationMark from '../../component/assets/icon.png';
import logo from './assets/logo.png';
import expand from './assets/expand.png';
import info from './assets/info.png';
import icon1 from './assets/contactUsMicon1.png';
import icon2 from './assets/contactUsMicon2.png';
import icon3 from './assets/contactUsMicon3.png';
import icon4 from './assets/contactUsMicon4.png';
import { CalendarOutlined } from '@ant-design/icons';
import request from '../../request/request';
import { useRequest } from 'ahooks';
import moment from 'moment';
import { debounce } from 'lodash';
import intl from 'react-intl-universal';
import { SimpleMap } from '../../component/map/map';
import { useHistory } from 'react-router-dom';

export async function sendEmail(data: {
  gender: string;
  name: string;
  email: string;
  content: string;
  phone: number;
  date: string;
  budget: string;
}) {
  return request.post(`${location.origin}/v1/sendEmail`, {
    data: data,
  });
}

export const ContactUs: FC = () => {
  const [form] = Form.useForm();
  const [formForMobile] = Form.useForm();
  const [confirm, setConfirm] = useState(false);
  const [openMobileInfo, setOpenMobileInfo] = useState(false);
  const labelCol = {
    span: 6,
  };

  const wrapperCol = {
    span: 18,
  };
  const sendEmailReq = useRequest(sendEmail, {
    manual: true,
    onSuccess: () => {
      message.success(intl.get('successMsg'));
      form.resetFields();
      formForMobile.resetFields();
      setOpenMobileInfo(false);
    },
  });
  const [screenSize, setScreenSize] = useState<number>(document.documentElement.clientWidth);
  const resize = async () => {
    const offsetWid = document.documentElement.clientWidth;
    setScreenSize(offsetWid);
  };
  window.addEventListener('resize', debounce(resize, 20));
  const history = useHistory();
  useEffect(() => {
    // 获取不到重新更新路由
    if (intl.get('AZ').length === 0) {
      history.push('/main/contact-us');
    }
  }, []);

  return (
    <>
      {screenSize > 800 ? (
        <div className={styles.contactUsWrap}>
          <div style={{ height: '50vh' }}>
            <SimpleMap />
          </div>
          <div className={styles.formTable}>
            <Form
              name="basic"
              initialValues={{ remember: true }}
              layout={'vertical'}
              form={form}
              onFinish={() => {
                const { name, phone, email, notes, gender, date, budget } = form.getFieldsValue();
                sendEmailReq.run({
                  name: name,
                  email: email,
                  content: notes,
                  phone: phone,
                  gender: gender,
                  date: moment(date).format('YYYY-MM-DD'),
                  budget: budget,
                });
              }}
            >
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}
                className={styles.nameRow}
              >
                <Form.Item
                  label={<span style={{ color: '#ffffff' }}>{intl.get('name')}</span>}
                  name="name"
                  rules={[{ required: true, message: '\n' + intl.get('inputInfo') }]}
                  className={styles.nameItem}
                >
                  <Input className={styles.inputStyle} placeholder={intl.get('inputInfo')} />
                </Form.Item>
                <Form.Item name="gender" initialValue={'先生'}>
                  <Radio.Group>
                    <Radio value={'先生'} style={{ color: '#ffffff' }}>
                      {intl.get('sir')}
                    </Radio>
                    <Radio value={'女士'} style={{ color: '#ffffff' }}>
                      {intl.get('madam')}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <Form.Item
                label={<span style={{ color: '#ffffff' }}>{intl.get('phone')}</span>}
                name="phone"
                rules={[{ required: true, message: '\n' + intl.get('inputInfo') }]}
                className={styles.phoneItem}
              >
                <Input className={styles.inputStyle} placeholder={intl.get('inputInfo')} />
              </Form.Item>
              <Form.Item
                label={<span style={{ color: '#ffffff' }}>{intl.get('email')}</span>}
                name="email"
                // rules={[
                //   {
                //     pattern: new RegExp(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/),
                //     message: '請輸入正確的郵箱格式',
                //   },
                // ]}
              >
                <Input className={styles.inputStyle} placeholder={intl.get('inputInfo')} />
              </Form.Item>
              <Form.Item
                label={<span style={{ color: '#ffffff' }}>{intl.get('budget')}</span>}
                name="budget"
              >
                <Input className={styles.inputStyle2} placeholder={intl.get('inputInfo')} />
              </Form.Item>
              <Form.Item
                label={<span style={{ color: '#ffffff' }}>{intl.get('date')}</span>}
                name="date"
              >
                <DatePicker
                  className={styles.inputStyle2}
                  placeholder={intl.get('inputDate')}
                  dropdownClassName={styles.popupDate}
                  suffixIcon={<CalendarOutlined style={{ color: '#cccccc' }} />}
                />
              </Form.Item>
              <Form.Item
                label={<span style={{ color: '#ffffff' }}>{intl.get('other')}</span>}
                name="notes"
              >
                <Input.TextArea
                  className={styles.inputStyle}
                  style={{ height: '132px' }}
                  placeholder={intl.get('commentHere')}
                />
              </Form.Item>
              <Form.Item>
                <div className={styles.submitBar}>
                  <Button type="primary" htmlType="submit">
                    {intl.get('submit')}
                  </Button>
                  <Button
                    onClick={() => {
                      setConfirm(true);
                    }}
                  >
                    {intl.get('reset')}
                  </Button>
                </div>
              </Form.Item>
            </Form>
            <Modal visible={confirm} footer={null} closable={false} mask={false} width={424}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'start',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                <img src={exclamationMark} alt={''} style={{ marginRight: '15px' }} />
                <div>
                  <p
                    style={{
                      fontSize: '16px',
                      fontWeight: 500,
                      marginBottom: 0,
                      color: 'rgba(0, 0, 0, 0.85)',
                    }}
                  >
                    {intl.get('confirmPopUp1')}
                  </p>
                  <p
                    style={{
                      fontSize: '14px',
                      fontWeight: 400,
                      marginBottom: 0,
                      color: 'rgba(0, 0, 0, 0.65)',
                      lineHeight: '48px',
                    }}
                  >
                    {intl.get('confirmPopUp2')}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginLeft: 'auto',
                  width: '140px',
                }}
              >
                <Button
                  onClick={() => {
                    setConfirm(false);
                    form.resetFields();
                  }}
                >
                  {intl.get('reset')}
                </Button>
                <Button
                  type={'primary'}
                  onClick={() => {
                    setConfirm(false);
                  }}
                >
                  {intl.get('cancel')}
                </Button>
              </div>
            </Modal>
          </div>
        </div>
      ) : (
        <div className={styles.contactUsWrapMobile}>
          <div className={styles.contactBackImg}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
                width: 130,
                marginLeft: '10%',
                paddingTop: 30,
                paddingBottom: 30,
              }}
            >
              <img src={logo} alt={''} />
              <h1 style={{ color: '#ffffff' }}>{intl.get('contactUs')}</h1>
            </div>
          </div>

          <div
            style={{
              marginTop: '5%',
              marginBottom: '5%',
              width: '100%',
              paddingLeft: '10%',
              paddingRight: '10%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'start',
                width: '40%',
              }}
            >
              <img src={icon1} style={{ width: '15%', marginRight: '5%' }} />
              <div>
                <h1 style={{ color: '#ffffff' }}>{intl.get('addr')}</h1>
                <p style={{ color: '#ffffff' }}>{intl.get('currentAdd')}</p>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'start',
                width: '40%',
              }}
            >
              <img src={icon2} style={{ width: '15%', marginRight: '5%', marginTop: '5%' }} />
              <div>
                <h1 style={{ color: '#ffffff' }}>{intl.get('fax')}</h1>
                <p style={{ color: '#ffffff' }}>+852-25278177</p>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: '5%',
              marginBottom: '5%',
              width: '100%',
              paddingLeft: '10%',
              paddingRight: '10%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'start',
                width: '40%',
              }}
            >
              <img src={icon3} style={{ width: '15%', marginRight: '5%', marginTop: '5%' }} />
              <div>
                <h1 style={{ color: '#ffffff' }}>{intl.get('phone')}</h1>
                <p style={{ color: '#ffffff' }}>+852-25276811</p>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'start',
                width: '40%',
              }}
            >
              <img src={icon4} style={{ width: '15%', marginRight: '5%', marginTop: '5%' }} />
              <div>
                <h1 style={{ color: '#ffffff' }}>{intl.get('email')}</h1>
                <p style={{ color: '#ffffff' }}>info@idandc.com.hk</p>
              </div>
            </div>
          </div>

          <div style={{ width: '100%', height: '35vh' }}>
            <SimpleMap />
          </div>
          <div style={{ textAlign: 'center' }}>
            <Button
              style={{
                width: 300,
                height: 70,
                marginTop: 60,
                marginBottom: 60,
              }}
              className={styles.mobileContactButton}
              onClick={() => {
                setOpenMobileInfo(true);
              }}
            >
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <div style={{ width: '100%' }}>
                  <h2
                    style={{
                      textAlign: 'left',
                      marginBottom: 5,
                      fontSize: '14px',
                      color: '#ffffff',
                    }}
                  >
                    <img src={info} alt={''} style={{ marginRight: '5px' }} />
                    {intl.get('contactUs')}
                  </h2>
                  <p
                    style={{ textAlign: 'left', fontSize: '10px', margin: '0', lineHeight: '1.2' }}
                  >
                    {intl.get('usBtInfo1')}
                  </p>
                  <p style={{ textAlign: 'left', fontSize: '10px' }}>{intl.get('usBtInfo2')}</p>
                </div>
                <img src={expand} alt={''} />
              </div>
            </Button>
          </div>
        </div>
      )}
      <Modal
        destroyOnClose
        className={styles.mobileModal}
        width={350}
        title={
          <>
            <h2
              style={{
                textAlign: 'left',
                marginBottom: 5,
                fontSize: '14px',
                color: '#ffffff',
              }}
            >
              <img src={info} alt={''} style={{ marginRight: '5px' }} />
              {intl.get('contactUs')}
            </h2>
          </>
        }
        visible={openMobileInfo}
        onCancel={() => {
          setOpenMobileInfo(false);
          formForMobile.resetFields();
        }}
        footer={
          <div style={{ textAlign: 'center' }}>
            <Button
              style={{
                width: 170,
                height: 37,
                marginBottom: 10,
                marginTop: 10,
                backgroundColor: '#35363A ',
                color: '#ffffff',
              }}
              onClick={() => {
                formForMobile.submit();
              }}
            >
              {intl.get('confirm')}
            </Button>
            <p style={{ color: '#ffffff' }}>{intl.get('confirmInfo')}</p>
          </div>
        }
      >
        <Form
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name="basic"
          layout="horizontal"
          initialValues={{ remember: true }}
          form={formForMobile}
          onFinish={() => {
            const {
              name,
              phone,
              email,
              notes,
              gender,
              date,
              budget,
            } = formForMobile.getFieldsValue();
            sendEmailReq.run({
              name: name,
              email: email,
              content: notes,
              phone: phone,
              gender: gender,
              date: moment(date).format('YYYY-MM-DD'),
              budget: budget,
            });
          }}
        >
          <Form.Item name="gender" initialValue={'先生'}>
            <Radio.Group>
              <Radio value={'先生'} style={{ color: '#ffffff' }}>
                {intl.get('sir')}
              </Radio>
              <Radio value={'女士'} style={{ color: '#ffffff' }}>
                {intl.get('madam')}
              </Radio>
            </Radio.Group>
          </Form.Item>
          {/*<Form.Item*/}
          {/*  label={<span style={{ color: '#ffffff' }}>姓名</span>}*/}
          {/*  name="name"*/}
          {/*  rules={[{ required: true, message: '\n' + '請輸入你的姓名' }]}*/}
          {/*  className={styles.nameItem}*/}
          {/*>*/}
          {/*  <Input className={styles.inputStyleM} placeholder={'請輸入'} />*/}
          {/*</Form.Item>*/}
          <Form.Item
            label={<span style={{ color: '#ffffff' }}>{intl.get('name')}</span>}
            name="name"
            rules={[{ required: true, message: '\n' + intl.get('inputInfo') }]}
            className={styles.phoneItem}
          >
            <Input className={styles.inputStyleM} placeholder={intl.get('inputInfo')} />
          </Form.Item>
          <Form.Item
            label={<span style={{ color: '#ffffff' }}>{intl.get('phone')}</span>}
            name="phone"
            rules={[{ required: true, message: '\n' + intl.get('inputInfo') }]}
            className={styles.phoneItem}
          >
            <Input className={styles.inputStyleM} placeholder={intl.get('inputInfo')} />
          </Form.Item>
          <Form.Item
            label={<span style={{ color: '#ffffff' }}>{intl.get('budget')}</span>}
            name="budget"
          >
            <Input className={styles.inputStyleM} placeholder={intl.get('inputInfo')} />
          </Form.Item>
          <Form.Item
            label={<span style={{ color: '#ffffff' }}>{intl.get('email')}</span>}
            name="email"
          >
            <Input className={styles.inputStyleM} placeholder={intl.get('inputInfo')} />
          </Form.Item>
          <Form.Item
            label={<span style={{ color: '#ffffff' }}>{intl.get('date')}</span>}
            name="date"
          >
            <DatePicker
              className={styles.inputStyleM}
              placeholder={intl.get('inputDate')}
              style={{ width: '100%' }}
              dropdownClassName={styles.popupDate}
              suffixIcon={<CalendarOutlined style={{ color: '#cccccc' }} />}
            />
          </Form.Item>
          <Form.Item
            label={<span style={{ color: '#ffffff' }}>{intl.get('other')}</span>}
            name="notes"
          >
            <Input className={styles.inputStyleM} placeholder={intl.get('commentHere')} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default {
  name: 'Name',
  phone: 'Phone Number',
  email: 'Email',
  budget: 'Budget',
  date: 'Date',
  other: 'Unit Category',
  sir: 'Mr.',
  madam: 'Miss.',
};

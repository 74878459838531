import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import enUS from 'antd/es/locale/en_US';
import moment from 'moment';
import 'moment/locale/zh-cn';
import './app.css';
import { BasicLayout } from '../layouts/BasicLayout';
import AboutUs from '../pages/aboutUs';
import { OtherLayout } from '../layouts/OtherLayout';
import { routeMap } from '../router';
import intl from 'react-intl-universal';
import zh from '../il8n/zh';
import en from '../il8n/en';
import { emit } from '../utils/emit';
import { Enum } from '../interfaces/enum';

const locale = intl.getInitOptions()?.currentLocale || Enum.中文;

moment.locale(locale === Enum.中文 ? 'zh-ch' : 'en-us');

function App() {
  const [antdLang, setAntdLang] = useState(zhCN);

  useEffect(() => {
    emit.on('change_language', (lang: string) => loadLocales(lang)); // 监听语言改变事件
    loadLocales();
  }, []);

  const loadLocales = (lang: string = Enum.中文) => {
    intl
      .init({
        currentLocale: lang,
        locales: {
          'zh-CN': zh,
          'en-US': en,
        },
      })
      .then(async () => {
        setAntdLang(lang === Enum.中文 ? zhCN : enUS);
      });
  };

  return (
    <ConfigProvider locale={antdLang}>
      <Switch>
        <Route path="/about-us">
          <BasicLayout>
            <Switch>
              <Route
                path="/about-us"
                key="/about-us"
                exact={true}
                render={(routeProps) => {
                  return <AboutUs />;
                }}
              />
              <Route path="*">
                <Redirect to={'/about-us'} />
              </Route>
            </Switch>
          </BasicLayout>
        </Route>
        <Route path="/main">
          <OtherLayout>
            <Switch>
              {routeMap.map((item) => (
                <Route
                  path={item.path}
                  key={item.path}
                  exact={true}
                  render={(routeProps) => {
                    return <item.component />;
                  }}
                />
              ))}
              <Route path="*">
                <Redirect to={'/main/renovation-design'} />
              </Route>
            </Switch>
          </OtherLayout>
        </Route>
        <Route path="*">
          <Redirect
            to={{
              pathname: '/about-us',
            }}
          />
        </Route>
      </Switch>
    </ConfigProvider>
  );
}

export default App;

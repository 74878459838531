import React, { FC, useState } from 'react';
import styles from './index.module.scss';
import footerIcon from './assets/footIcon.png';
import address from './assets/address.png';
import email from './assets/email.png';
import fax from './assets/fax.png';
import ipAddress from './assets/ipAddress.png';
import phone from './assets/phone.png';
import copyright from './assets/copyright.png';
import footerIconM from './assets/footerTitleiconM.png';
import footerTitleM from './assets/footerTitleM.png';
import { message, Typography } from 'antd';
import copy from 'copy-to-clipboard';
import { debounce } from 'lodash';
import intl from 'react-intl-universal';

export const BasicFooter: FC = () => {
  const [screenSize, setScreenSize] = useState<number>(document.documentElement.clientWidth);
  const { Text } = Typography;
  const resize = async () => {
    const offsetWid = document.documentElement.clientWidth;
    setScreenSize(offsetWid);
  };
  window.addEventListener('resize', debounce(resize, 20));

  const goCopy = (str: string) => {
    message.success('複製成功！');
    copy(str);
  };
  return (
    <>
      {screenSize > 800 ? (
        <div className={styles.footerWrap}>
          <div className={styles.footerContent}>
            <div className={styles.footerTitleWrap}>
              <img src={footerIcon} alt={''} className={styles.footerIcon} />
              <p className={styles.footerTitle}>
                日盛室內設計工程有限公司
                <br /> interior design & construction company limited
              </p>
            </div>
            <div className={styles.borderDiv} />
            <div>
              <span className={styles.footerInfo}>
                <img src={address} alt={''} style={{ marginRight: '5px', marginLeft: '15px' }} />
                <Text
                  onClick={() => goCopy(intl.get('currentAdd'))}
                  style={{ color: '#ffffff', lineHeight: '27px', width: 340 }}
                  ellipsis={{ tooltip: true }}
                >
                  {intl.get('currentAdd')}
                </Text>
              </span>
              <span className={styles.footerInfo}>
                <img src={phone} alt={''} style={{ marginRight: '5px', marginLeft: '15px' }} />
                Tel: <span onClick={() => goCopy('+852-2527 6811')}>+852-2527 6811</span>
              </span>
              <span className={styles.footerInfo}>
                <img src={fax} alt={''} style={{ marginRight: '5px', marginLeft: '15px' }} />
                Fax: <span onClick={() => goCopy('+852-2527 8177')}>+852-2527 8177</span>
              </span>
              <br />
              <span className={styles.footerInfo}>
                <img src={email} alt={''} style={{ marginRight: '5px', marginLeft: '15px' }} />
                Email: <span onClick={() => goCopy('info@idandc.com.hk')}>info@idandc.com.hk</span>
              </span>
              <span className={styles.footerInfo}>
                <img src={ipAddress} alt={''} style={{ marginRight: '5px', marginLeft: '15px' }} />
                Website: <span onClick={() => goCopy('www.idandc.com.hk')}>www.idandc.com.hk</span>
              </span>
            </div>
          </div>
          <div className={styles.copyRight}>
            <div className={styles.copyRightWrap}>
              <span className={styles.copyRightContext}>
                <img
                  src={copyright}
                  alt={''}
                  style={{ marginBottom: '3px', marginRight: '3px', width: '12px', height: '12px' }}
                />
                2021 id&c Co. Ltd. | 日盛室內設計工程有限公司. All Rights Reserved.
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.footerWrapMobile}>
          <div className={styles.footerContentMobile}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginLeft: '13%',
              }}
            >
              <img src={footerIconM} style={{ width: '6%' }} alt={''} />
              <img src={footerTitleM} style={{ width: '76%', marginRight: '20%' }} alt={''} />
            </div>
            <div style={{ marginLeft: '32%', transform: 'translateX(-20%)' }}>
              <div className={styles.footerInfoM}>
                <img
                  src={address}
                  alt={''}
                  style={{ marginRight: '5px', marginLeft: '15px', width: 10 }}
                />
                <Text
                  onClick={() => goCopy(intl.get('currentAdd'))}
                  style={{ color: '#ffffff', lineHeight: '27px', width: 230 }}
                  ellipsis={{ tooltip: true }}
                >
                  {intl.get('currentAdd')}
                </Text>
              </div>

              <div className={styles.footerInfoM}>
                <img
                  src={phone}
                  alt={''}
                  style={{ marginRight: '5px', marginLeft: '15px', width: 10 }}
                />
                Tel:&nbsp;
                <span onClick={() => goCopy('+852-2527 6811')} style={{ fontWeight: 600 }}>
                  +852-2527 6811
                </span>
              </div>
              <div className={styles.footerInfoM}>
                <img
                  src={fax}
                  alt={''}
                  style={{ marginRight: '5px', marginLeft: '15px', width: 10 }}
                />
                Fax:&nbsp;
                <span onClick={() => goCopy('+852-2527 8177')} style={{ fontWeight: 600 }}>
                  +852-2527 8177
                </span>
              </div>
              <div className={styles.footerInfoM}>
                <img
                  src={email}
                  alt={''}
                  style={{ marginRight: '5px', marginLeft: '15px', width: 10 }}
                />
                Email:&nbsp;
                <span onClick={() => goCopy('info@idandc.com.hk')} style={{ fontWeight: 600 }}>
                  info@idandc.com.hk
                </span>
              </div>
              <div className={styles.footerInfoM}>
                <img
                  src={ipAddress}
                  alt={''}
                  style={{ marginRight: '5px', marginLeft: '15px', width: 10 }}
                />
                Website: &nbsp;
                <span onClick={() => goCopy('www.idandc.com.hk')} style={{ fontWeight: 600 }}>
                  www.idandc.com.hk
                </span>
              </div>
            </div>
            <div style={{ height: '10px' }} />
            <div className={styles.copyRight}>
              <div className={styles.copyRightWrapM}>
                <span className={styles.copyRightContextM}>
                  <img
                    src={copyright}
                    alt={''}
                    style={{
                      marginBottom: '3px',
                      marginRight: '3px',
                      width: '11px',
                      height: '11px',
                    }}
                  />
                  2021 id&c Co. Ltd. | 日盛室內設計工程有限公司. All Rights Reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

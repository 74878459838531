import React, { FC, useEffect, useState } from 'react';
import styles from './index.module.scss';
import topBanner from './assets/topbanner2x.png';
import whoTitle from './assets/WhoTitle2x.png';
import whatTitle from './assets/WhatTitle2x.png';
import whenTitle from './assets/WhenTitle2x.png';
import whereTitle from './assets/whereTitle2x.png';
import howTitle from './assets/howTitle2x.png';
import icon from './assets/icon.png';
import banner1 from './assets/whatbanner1.png';
import banner2 from './assets/whatbanner2.png';
import banner3 from './assets/whatbanner3.png';
import CCBA from '../../assets/trademark/CCBA.png';
import BlackRock from '../../assets/trademark/BlackRock.png';
import CSL from '../../assets/trademark/CSL.png';
import TIMESDF from '../../assets/trademark/TIMESDF.png';
import PANDAHOTEL from '../../assets/trademark/PANDAHOTEL.png';
import ICC from '../../assets/trademark/ICC.png';
import DHL from '../../assets/trademark/DHL.png';
import AXA from '../../assets/trademark/AXA.png';
import swatch from '../../assets/trademark/swatch.png';
import three from '../../assets/trademark/3.png';
import abc from '../../assets/trademark/abc.png';
import hcs from '../../assets/trademark/hutchison.png';
import icbc from '../../assets/trademark/ICBC.png';
import k11 from '../../assets/trademark/k11.png';
import lee from '../../assets/trademark/lee.png';
import az from '../hotArticles/assets/AZs.png';
import aia from './assets/aia.png';
import cbre from './assets/cbre.png';
import dpark from './assets/dpark.png';
import hgc from './assets/hgc.png';
import horizon from './assets/horizon.png';
import jll from './assets/jll.png';
import mac from './assets/mac.png';
import man from './assets/man.png';
import psi from './assets/psi.png';
import razer from './assets/razer.png';
import tes from './assets/tes.png';
import ttb from './assets/ttb.png';
import yilang from './assets/yilang.png';
import hotpot from './assets/hotpot.png';
import goUp from './assets/goup.png';
import cert from './assets/cert.png';
import topBannerM from './assets/mobilePics/headerM.png';
import certM from './assets/mobilePics/certificateM.png';
import whoTitleM from './assets/mobilePics/whoTitleM.png';
import interiorTitle from './assets/mobilePics/interiorTitle.png';
import interiorBm from './assets/mobilePics/interiorBm.png';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import { message, Space } from 'antd';
import copy from 'copy-to-clipboard';
import intl from 'react-intl-universal';
import threeM from './assets/mobilePics/3M.png';
import abcM from './assets/mobilePics/abcM.png';
import axaM from './assets/mobilePics/axaM.png';
import brM from './assets/mobilePics/brM.png';
import clsM from './assets/mobilePics/cslM.png';
import dhlM from './assets/mobilePics/dhlM.png';
import hcsm from './assets/mobilePics/hcsm.png';
import icbcM from './assets/mobilePics/icbcM.png';
import iccM from './assets/mobilePics/iccM.png';
import jianhangM from './assets/mobilePics/jianhangM.png';
import k11M from './assets/mobilePics/k11M.png';
import phM from './assets/mobilePics/phM.png';
import swatchM from './assets/mobilePics/swatchM.png';
import tdfM from './assets/mobilePics/tdfM.png';
import { SimpleMap } from '../../component/map/map';

const howImgObj = {
  0: [
    CCBA,
    icbc,
    BlackRock,
    CSL,
    TIMESDF,
    PANDAHOTEL,
    ICC,
    DHL,
    AXA,
    k11,
    three,
    hcs,
    swatch,
    aia,
    jll,
  ],
  1: [az, abc, lee, cbre, dpark, hgc, horizon, mac, man, psi, razer, tes, ttb, yilang, hotpot],
};

const howImgObjM = {
  0: [
    jianhangM,
    icbcM,
    brM,
    clsM,
    tdfM,
    phM,
    iccM,
    dhlM,
    axaM,
    k11M,
    threeM,
    hcsm,
    swatchM,
    aia,
    jll,
    az,
  ],
  1: [abcM, lee, cbre, dpark, hgc, horizon, mac, man, psi, razer, tes, ttb, yilang, hotpot],
};

const AboutUs: FC = () => {
  let timer: NodeJS.Timeout;
  const timerInterval = 3000;
  const history = useHistory();
  const [imgArr, setImgArr] = useState([banner1, banner2, banner3]);
  const [animationExit, setAnimationExit] = useState(false);
  const [animationEnter, setAnimationEnter] = useState(false);
  const [animationLast, setAnimationLast] = useState(false);
  const [initialAnimation, setInitialAnimation] = useState(false);
  const [howImgIndex, setHowImgIndex] = useState(0);
  const [howImgIndexM, setHowImgIndexM] = useState(0);
  const [howImgs, setHowImgs] = useState(howImgObj[howImgIndex]);
  const [howImgsM, setHowImgsM] = useState(howImgObjM[howImgIndex]);
  const [isUpIcon, setIsUpIcon] = useState(false);

  const scroll = async () => {
    const isArrived =
      document.documentElement.scrollTop + 300 > document.documentElement.clientHeight;

    if (initialAnimation !== isArrived) {
      await setInitialAnimation(isArrived);
    }
    await setIsUpIcon(
      document.documentElement.scrollTop - 200 > document.documentElement.clientHeight,
    );
  };
  window.addEventListener('scroll', debounce(scroll, 20));

  const [screenSize, setScreenSize] = useState<number>(document.documentElement.clientWidth);
  const resize = async () => {
    const offsetWid = document.documentElement.clientWidth;
    setScreenSize(offsetWid);
  };
  window.addEventListener('resize', debounce(resize, 20));

  useEffect(() => {
    const newArr = [...imgArr];
    timer = setTimeout(() => {
      const deleteImg = newArr.shift();
      deleteImg && newArr.push(deleteImg);
      setAnimationExit(true);
      setTimeout(() => {
        setAnimationEnter(true);
        setTimeout(() => {
          setImgArr(newArr);
          setAnimationEnter(false);
          setAnimationExit(false);
          setAnimationLast(true);
          setTimeout(() => {
            setAnimationLast(false);
          }, 600);
        }, 900);
      }, 400);
    }, timerInterval);
  }, [imgArr]);

  const toPage = (index: number) => {
    setHowImgIndex(index);
    setHowImgs(howImgObj[index]);
  };
  const toPageM = (index: number) => {
    setHowImgIndexM(index);
    setHowImgsM(howImgObjM[index]);
  };

  const toUp = () => {
    window.scrollTo({
      top: document.documentElement.clientHeight,
      behavior: 'smooth',
    });
  };

  const goCopy = (str: string) => {
    message.success('複製成功！');
    copy(str);
  };

  return (
    <>
      {screenSize > 800 ? (
        <div className={styles.about}>
          <div
            className={
              initialAnimation
                ? `${styles.topBannerWrap} ${styles.floatToRight}`
                : styles.topBannerWrap
            }
          >
            <img src={topBanner} alt="banner" className={styles.bannerImg} />
            <div className={styles.titleText}>
              <img src={cert} alt={''} />
            </div>
          </div>
          <div className={styles.fatherBox}>
            <div className={styles.outBoxWrapper}>
              <div
                className={
                  initialAnimation
                    ? `${styles.leftBoxWrap} ${styles.floatToRight}`
                    : styles.leftBoxWrap
                }
                id="what"
              >
                <img src={whatTitle} alt="what" className={styles.whatTitle} />
                <div className={styles.whatContent}>
                  <div className={styles.whatContentPhotos}>
                    <div className={styles.whatPhoto}>
                      <img
                        src={imgArr[0]}
                        alt=""
                        style={animationEnter ? { opacity: 0 } : {}}
                        className={
                          animationExit
                            ? `${styles.firstImg} ${styles.firstSwitch}`
                            : styles.firstImg
                        }
                        onClick={() => {
                          if (imgArr[0].includes('whatbanner1')) {
                            history.push(`/main/renovation-design#${intl.get('SDFL')}`);
                          } else if (imgArr[0].includes('whatbanner2')) {
                            history.push(`/main/renovation-design#${intl.get('HTCL')}`);
                          } else {
                            history.push(`/main/renovation-design#${intl.get('HKNC')}`);
                          }
                        }}
                      />
                      <img
                        src={imgArr[1]}
                        alt=""
                        className={
                          animationEnter ? `${styles.twoImg} ${styles.bannerSwitch}` : styles.twoImg
                        }
                        onClick={() => history.push(`/main/renovation-design#${intl.get('HTCL')}`)}
                      />
                      <img
                        src={imgArr[0]}
                        alt=""
                        style={{ opacity: 0, zIndex: 33 }}
                        className={
                          animationEnter ? `${styles.lastImg} ${styles.lastEnterSwitch}` : ''
                        }
                      />
                      <img
                        src={imgArr[2]}
                        alt=""
                        className={
                          animationEnter ? `${styles.lastImg} ${styles.lastSwitch}` : styles.lastImg
                        }
                        onClick={() => history.push(`/main/renovation-design#${intl.get('HKNC')}`)}
                        // className={
                        //   animationEnter
                        //     ? `${styles.lastImg} ${styles.lastSwitch}`
                        //     : animationLast
                        //     ? `${styles.lastImg} ${styles.lastEnterSwitch}`
                        //     : styles.lastImg
                        // }
                      />
                    </div>
                  </div>
                  <div>
                    <div className={styles.programWrap}>
                      <p className={styles.contactTitle}>{intl.get('HK')}</p>
                      <p className={styles.contact} style={{ width: '55%' }}>
                        {intl.get('HKArea')}
                      </p>
                      <p className={styles.contact} style={{ width: '55%' }}>
                        {intl.get('HKDescription')}
                      </p>
                      <br />
                      <p className={styles.contactTitle}>{intl.get('HTL')}</p>
                      <p className={styles.contact} style={{ width: '55%' }}>
                        {intl.get('HTLArea')}
                      </p>
                      <p className={styles.contact} style={{ width: '55%' }}>
                        {intl.get('HTLDescription')}
                      </p>
                      <br />
                      <p className={styles.contactTitle}>{intl.get('NCL')}</p>
                      <p className={styles.contact} style={{ width: '55%' }}>
                        {intl.get('NCLArea')}
                      </p>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p className={styles.contact} style={{ width: '55%' }}>
                          {intl.get('NCLDescription')}
                        </p>
                        <a
                          className={
                            intl.get('btn') === 'eng' ? styles.whatEngButton : styles.whatButton
                          }
                          onClick={() => history.push('/main/renovation-design')}
                        />
                      </div>
                      {/*<div style={{ height: '50px' }}></div>*/}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ zIndex: 999 }}
                className={intl.get('btn') === 'eng' ? styles.whoEng : styles.whoCn}
              >
                <div
                  className={
                    initialAnimation
                      ? `${styles.rightBoxWrap} ${styles.floatToLeft}`
                      : styles.rightBoxWrap
                  }
                  id="who"
                >
                  <img src={whoTitle} alt="who" className={styles.whoTitle} />
                  <div className={styles.whoContent}>
                    <div>
                      <div className={styles.whoTitleImg}>
                        <img src={interiorTitle} alt="who" />
                      </div>
                      <div className={styles.whoContentWrap}>
                        <div className={styles.whoContentWords1}>{intl.get('companyName')}</div>
                        <div>
                          <div>
                            <img src={icon} alt="what" className={styles.whoContentIcon} />
                            <p className={styles.whoContentWords2}>{intl.get('companyInfo1')}</p>
                            <p className={styles.whoContentWords2}>{intl.get('companyInfo2')}</p>
                          </div>
                        </div>
                        <p className={styles.whoContentWords3}>{intl.get('companyInfo3')}</p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'end',
                          margin: '60px 10px 0 20px',
                        }}
                      >
                        <img
                          src={interiorBm}
                          alt="who"
                          width="60%"
                          style={{ marginRight: '9px' }}
                        />
                        <div className={styles.contactWrap}>
                          <p className={styles.contactTitle}>{intl.get('contactInfo')}</p>
                          <p className={styles.contact}>
                            {intl.get('T')}
                            <span onClick={() => goCopy('+852-2527 6811')}>+852-2527 6811</span>
                          </p>
                          <p className={styles.contact}>
                            {intl.get('F')}
                            <span onClick={() => goCopy('+852-2527 8177')}>+852-2527 8177</span>
                          </p>
                          <p className={styles.contact}>
                            {intl.get('E')}
                            <span onClick={() => goCopy('info@idandc.com.hk')}>
                              info@idandc.com.hk
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/*<img src={certificate} alt={''} className={styles.whoCertificate} />*/}
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.outBoxWrapper} ${styles.outBoxWrapperPosition}`}>
              <div
                className={
                  initialAnimation
                    ? `${styles.leftBoxWrapForWhere} ${styles.floatToRight}`
                    : styles.leftBoxWrapForWhere
                }
                id="where"
              >
                <img src={whereTitle} alt="where" className={styles.whereTitle} />
                <div className={styles.whereContent}>
                  <div className={styles.whereContentWrap}>
                    <div className={styles.mapWrap}>
                      <SimpleMap />
                      {/*<img src={maps} alt="" />*/}
                    </div>
                    <div className={styles.addressDetail}>
                      <p className={styles.addressTitle}>{intl.get('address')}</p>
                      <p className={styles.address}>{intl.get('currentAdd')}</p>
                      <div
                        className={
                          intl.get('btn') === 'eng' ? styles.whereEngButton : styles.whereButton
                        }
                        onClick={() => {
                          window.open(
                            'https://www.google.com/maps/place/Southmark/@22.2490626,114.1627062,17z/data=!3m1!4b1!4m5!3m4!1s0x34040018d827732b:0xbba21061bdcde893!8m2!3d22.2490626!4d114.1648949',
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={intl.get('btn') === 'eng' ? styles.whenEng : styles.whenCn}>
                <div
                  className={
                    initialAnimation
                      ? `${styles.rightBoxWrap} ${styles.floatToLeft}`
                      : styles.rightBoxWrap
                  }
                  id="when"
                >
                  <img src={whenTitle} alt="when" className={styles.whenTitle} />
                  <div className={styles.whenContent}>
                    <div className={styles.whenIntroWrap}>
                      <p className={styles.introTitle}>{intl.get('since')}</p>
                      <p className={styles.introInfo}>{intl.get('found')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                initialAnimation
                  ? `${styles.lastBoxWrap} ${styles.floatToLeft}`
                  : styles.lastBoxWrap
              }
              id="how"
            >
              <img src={howTitle} alt="how" className={styles.howTitle} />
              <div className={styles.howContent}>
                <div className={styles.howContentWrap}>
                  <div className={styles.howContentWrapTitle} />
                  <div className={styles.howImgDiv}>
                    <Space size={[10, 10]} wrap>
                      {howImgs.map((item: string) => (
                        <img alt="" src={item} key={item} />
                      ))}
                    </Space>
                  </div>
                  <div className={styles.pagination}>
                    {[0, 1].map((item) => (
                      <span
                        key={item}
                        onClick={() => toPage(item)}
                        className={styles.pagination}
                        style={{ opacity: item === howImgIndex ? 1 : 0.3 }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isUpIcon && <img alt="" src={goUp} className={styles.goUp} onClick={toUp} />}
        </div>
      ) : (
        <div className={styles.mobileAboutUsWrapper}>
          <div className={styles.MobileCenter}>
            <div className={styles.mobileHeader}>
              <img
                src={topBannerM}
                alt="banner"
                className={styles.bannerImgM}
                style={{ width: '85%' }}
              />
              <div className={styles.titleTextM}>
                <img src={certM} alt={''} style={{ width: '40%' }} />
              </div>
            </div>
            <div className={styles.whoMobile}>
              <div>
                <img src={whoTitleM} alt={''} style={{ width: '30%', marginLeft: '5%' }} />
              </div>
              <div className={styles.whoContentM}>
                <div>
                  <div style={{ padding: '10px 5% 0 5%' }}>
                    <img src={interiorTitle} alt="who" style={{ width: '100%' }} />
                    <div className={styles.whoContentWrapM}>
                      <div className={styles.whoContentWords1M}>{intl.get('companyName')}</div>
                      <div className={styles.whoContentWords2M}>
                        <img src={icon} alt="what" className={styles.whoContentIconM} />
                        {intl.get('companyInfo1')}
                        {intl.get('companyInfo2')}
                      </div>
                      <p className={styles.whoContentWords3M}>{intl.get('companyInfo3')}</p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'end',
                      margin: '0 10px 14px 10px',
                    }}
                  >
                    <img
                      src={interiorBm}
                      alt="who"
                      width="60%"
                      style={{ marginRight: '9px', paddingBottom: '10px' }}
                    />
                    <div className={styles.contactWrapM}>
                      <p className={styles.contactTitleM}>{intl.get('contactInfo')}</p>
                      <p className={styles.contactM2}>
                        {intl.get('T')}
                        <span onClick={() => goCopy('+852-2527 6811')}>+852-2527 6811</span>
                      </p>
                      <p className={styles.contactM2}>
                        {intl.get('F')}
                        <span onClick={() => goCopy('+852-2527 8177')}>+852-2527 8177</span>
                      </p>
                      <p className={styles.contactM2}>
                        {intl.get('E')}
                        <span onClick={() => goCopy('info@idandc.com.hk')}>info@idandc.com.hk</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.whatMobile}>
              <img src={whatTitle} alt="what" className={styles.whatTitleM} />
              <div className={styles.whatContentM}>
                <div className={styles.whatContentPhotosM}>
                  <div className={styles.whatPhotoM}>
                    <img
                      src={imgArr[0]}
                      alt=""
                      style={animationEnter ? { opacity: 0 } : {}}
                      className={
                        animationExit ? `${styles.firstImg} ${styles.firstSwitch}` : styles.firstImg
                      }
                      onClick={() => {
                        if (imgArr[0].includes('whatbanner1')) {
                          history.push(`/main/renovation-design#${intl.get('SDFL')}`);
                        } else if (imgArr[0].includes('whatbanner2')) {
                          history.push(`/main/renovation-design#${intl.get('HTCL')}`);
                        } else {
                          history.push(`/main/renovation-design#${intl.get('HKNC')}`);
                        }
                      }}
                    />
                    <img
                      src={imgArr[1]}
                      alt=""
                      className={
                        animationEnter ? `${styles.twoImg} ${styles.bannerSwitch}` : styles.twoImg
                      }
                      onClick={() => history.push(`/main/renovation-design#${intl.get('HTCL')}`)}
                    />
                    <img
                      src={imgArr[0]}
                      alt=""
                      style={{ opacity: 0, zIndex: 33 }}
                      className={
                        animationEnter ? `${styles.lastImg} ${styles.lastEnterSwitch}` : ''
                      }
                    />
                    <img
                      src={imgArr[2]}
                      alt=""
                      className={
                        animationEnter ? `${styles.lastImg} ${styles.lastSwitch}` : styles.lastImg
                      }
                      onClick={() => history.push(`/main/renovation-design#${intl.get('HKNC')}`)}
                    />
                  </div>
                </div>
                <div>
                  <div className={styles.programWrapM}>
                    <p className={styles.contactTitleM}>{intl.get('HK')}</p>
                    <p className={styles.contactM}>{intl.get('HKArea')}</p>
                    <p className={styles.contactMWhere}>{intl.get('HKDescription')}</p>
                    <br />
                    <p className={styles.contactTitleM}>{intl.get('HTL')}</p>
                    <p className={styles.contactM}>{intl.get('HTLArea')}</p>
                    <p className={styles.contactMWhere}>{intl.get('HTLDescription')}</p>
                    <br />
                    <p className={styles.contactTitleM}>{intl.get('NCL')}</p>
                    <p className={styles.contactM}>{intl.get('NCLArea')}</p>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p className={styles.contactMWhere}>{intl.get('NCLDescription')}</p>
                      <a
                        style={{ marginLeft: '10px' }}
                        className={
                          intl.get('btn') === 'eng' ? styles.whatEngButtonM : styles.whatButtonM
                        }
                        onClick={() => history.push('/main/renovation-design')}
                      />
                    </div>
                    {/*<div style={{ height: '50px' }}></div>*/}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.whenMobile}>
              <img src={whenTitle} alt="when" className={styles.whenTitleM} />
              <div className={styles.whenContentM}>
                <div className={styles.whenIntroWrapM}>
                  <p style={{ fontSize: '10pt', fontWeight: 600, marginBottom: 0 }}>
                    {intl.get('since')}
                  </p>
                  <p className={styles.introInfoM}>{intl.get('found')}</p>
                </div>
              </div>
            </div>
            <div className={styles.whereMobile}>
              <img src={whereTitle} alt="where" className={styles.whereTitleM} />
              <div className={styles.whereContentM}>
                <div className={styles.whereContentWrapM}>
                  <div className={styles.whereMapM}>
                    <SimpleMap />
                  </div>
                  <div className={styles.addressDetailM}>
                    <p className={styles.addressTitleM}>{intl.get('address')}</p>
                    <p className={styles.addressM}>{intl.get('currentAdd')}</p>
                    <div
                      className={
                        intl.get('btn') === 'eng' ? styles.whereEngButtonM : styles.whereButtonM
                      }
                      onClick={() => {
                        window.open(
                          'https://www.google.com/maps/place/Southmark/@22.2490626,114.1627062,17z/data=!3m1!4b1!4m5!3m4!1s0x34040018d827732b:0xbba21061bdcde893!8m2!3d22.2490626!4d114.1648949',
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.howMobile}>
              <img src={howTitle} alt="how" className={styles.howTitleM} />
              <div className={styles.howContentM}>
                <div className={styles.howContentWrapM}>
                  <div className={styles.howContentWrapTitleM} />
                  <div className={styles.howImgDivM}>
                    <Space size={[10, 10]} wrap>
                      {howImgsM.map((item: string) => (
                        <img alt="" src={item} key={item} style={{ width: 130, height: 50 }} />
                      ))}
                    </Space>
                  </div>
                  <div className={styles.paginationM}>
                    {[0, 1].map((item) => (
                      <span
                        key={item}
                        onClick={() => toPageM(item)}
                        className={styles.paginationM}
                        style={{ opacity: item === howImgIndexM ? 1 : 0.3 }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default AboutUs;

export default {
  SDFL: 'Shenzhen Duty Free (HK) Co. Ltd.',
  BRL: 'Black Rock (Hong Kong) Ltd.',
  HKNC: 'Hong Kong New Catering Ltd.',
  CB: 'China Construction Bank (Asia) Ltd.',
  HAL: 'Horizons Int’l Asset (HK) Ltd.',
  Sky: 'Sky Wise Catering Ltd.',
  HTCL: 'Hutchison Telephone Co. Ltd.',
  swimmingPool: '',
  HGCL: 'Hutchison Global Centre Ltd.',
  AZ: 'AZ Electronic Materials Ltd.',
  ICC: 'ICC Int’l Court of Arbitration Ltd.',
  TSG: 'The Swatch Group (HK) Ltd.',
  DHL: 'DHL Limited',
  CSL: 'CSL Limited',
  AXA: 'AXA China Region Insurance Co. Ltd.',
  RS: 'Razer (Asia Pacific) Pte Ltd.',
  TTB: 'TTB Partners Limited',
  DPCS: 'Discovery Park Commercial Services Ltd.',
  EPL: 'Elite Properties (H.K.) Limited',
  PSIL: 'Planning Services International (Asia) Ltd.',
  PandaHotel: 'Panda Hotel',
  TSGL: 'The Swatch Group (HK) Ltd.',
  Atest: 'a.testoni shop at Harbour City',
  zhonghuan: 'Office at Citibank Tower Central',
  queen: "Office at No. 8 Queen's Road Central",
  inputInfo: 'Input your information',
  inputDate: 'Input your date',
  commentHere: 'Please comment here',
  confirm: 'Confirm',
  confirmInfo: 'The information that we collect will be protected under our privacy policy',
  usBtInfo1: 'Please input your personal information, ',
  usBtInfo2: 'our staff will contact with you as soon as possible',
  submit: 'Submit',
  reset: 'Reset',
  cancel: 'Cancel',
  confirmPopUp1: 'Confirm to reset all information',
  confirmPopUp2: 'It will delete all filled information',
  successMsg: 'Thanks for your submit, we will contact with you as soon as possible',
};

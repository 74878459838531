export default {
  HK: '深圳免稅（香港）有限公司',
  HKArea: '面積：4305平方呎',
  HKDescription: '描述：深圳免稅金鐘多功能廳及辦公室',
  HTL: '和記電話有限公司',
  HTLArea: '面積：5,000平方呎',
  HTLDescription: '描述：青衣和記電訊大廈多功能廳',
  NCL: '香江花月',
  NCLArea: '面積：7,000平方呎',
  NCLDescription: '描述：九龍佐敦百誠大廈',
  aboutUs: '關於我們',
  interiorDesign: '裝修設計',
  interiorDesignCases: '裝修設計案例',
  recommendationLetter: '推薦信',
  viewBtn: '查看',
  contactUs: '聯繫我們',
  pics: '視圖',
  companyName: '日盛室內設計工程有限公司',
  companyInfo1:
    '是一家專業室內設計及承包公司，致力為業主和使用者提供最大化的實用性且持久的利益。 我司在室內裝修工程、設計以及翻新項目中，有著豐富和專業的經驗；其中包含：辦公室、住宅、餐廳等不同領域的服務。此外，我們已獲得香港屋宇署認可最高級別（I, II, III級）註冊小型工程牌照。詳細設計、跟進施工直至項目完成以及售後服務，我們優質的項目團隊與主要客戶決策者進行緊密合作，以實現客戶的要求並提供專業意見為準則。憑藉我們過去積累的經驗盡心盡力為客戶服務。',
  companyInfo2: '',
  companyInfo3: '',
  since: '始起於 2005',
  found:
    '日盛室內設計工程有限公司成立於2005年, 憑着超過16年豐富經驗及擁有的專業團隊，我們有信心能提供最好的服務予所有客人。',
  contactInfo: '聯繫資料',
  T: '電話：',
  F: '傳真：',
  E: '電郵：',
  address: '詳細地址',
  currentAdd: '香港黃竹坑業興街11號南匯廣場B座2519室',
  btn: 'cn',
  fax: '傳真',
  email: '電郵',
  phone: '電話',
  addr: '地址',
};

import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import 'keen-slider/keen-slider.min.css';
import styles from '../index.module.scss';
import arrowLeft from '../assets/arrowLeft.png';
import arrowRight from '../assets/arrowRight.png';
import { DisplayItem } from '../index';
import { Carousel } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import { useHistory } from 'react-router-dom';
import intl from 'react-intl-universal'

interface PhotosProps {
  show: boolean;
  photos: DisplayItem | undefined;
  setShow: Dispatch<SetStateAction<boolean>>;
  setPhotos: Dispatch<SetStateAction<number>>;
}

export const DisplayPhotos: FC<PhotosProps> = ({ show, photos, setPhotos, setShow }) => {
  const history = useHistory();
  const ref = useRef<CarouselRef>(null);

  useEffect(() => {
    if (show) {
      document.documentElement.scrollTop = 0;
    }
  }, [show]);

  const close = () => {
    history.push('/main/renovation-design');
    setPhotos(0);
    setShow(false);
  };

  return (
    <>
      {show && (
        <div className={styles.displayWrap}>
          <div className={styles.topNav}>
            <span className={styles.backButton} onClick={close} style={{ cursor: 'pointer' }}>
              {intl.get('interiorDesignCases')}
            </span>
            &gt;
            <span> {photos?.titles}</span>
            <CloseOutlined className={styles.closeOutlinedIcon} onClick={close} />
          </div>
          <div className={styles.slider}>
            <Carousel arrows ref={ref}>
              {photos?.photos.map((img, index) => (
                <div key={index}>
                  <img src={img} alt={''} className={styles.sliderSize} />
                </div>
              ))}
            </Carousel>
            <img
              src={arrowLeft}
              alt={''}
              className={`${styles.arrow} ${styles.arrowLeft}`}
              onClick={() => {
                ref.current?.prev();
              }}
            />
            <img
              src={arrowRight}
              alt={''}
              className={`${styles.arrow} ${styles.arrowRight}`}
              onClick={() => {
                ref.current?.next();
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

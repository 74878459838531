export default {
  name: '姓名',
  phone: '電話',
  email: '電子郵箱',
  budget: '預算',
  date: '開工日期',
  other: '查詢事項',
  sir: '先生',
  madam: '女士',
};

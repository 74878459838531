/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { message, notification } from 'antd';
import { Context, extend, RequestOptionsInit } from 'umi-request';

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

/**
 * 异常处理程序
 */
const errorHandler = (error: {
  // code: number,
  // msg: string,
  // trace_id: string
  response: Response;
  request: {
    url?: string;
    options?: RequestOptionsInit;
  };
}) => {
  const { response } = error;
  if (!response) {
    throw error;
  }
  if (response && response.status !== 200) {
    // if (response.status === 401) {
    //   auth.logout();
    // }
    if (response.status === 403) {
      window.location.href = `${window.location.origin}/403`;
    }
  }
  console.error(error);
  return response;
};

/**
 * 配置request请求时的默认参数
 */
const request = extend({
  errorHandler, // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie
});

// 给请求添加 token、参数驼峰转蛇形
// request.interceptors.request.use((url: string, options: RequestOptionsInit) => {
//   return {
//     url,
//     options: {
//       ...options,
//       headers: { AccessControlAllowOrigin: 'http://localhost:3001' },
//     },
//   };
// });

// 处理返回错误信息（先用 errorHandler，满足不了再在这里写）
// request.interceptors.response.use((response, options) => {
//   return response;
// });

request.use(async (ctx: Context, next) => {
  await next();
  if (ctx.res.code >= 500) {
    if (ctx.req.options.method === 'POST') {
      message.error(ctx.res.msg || '未知错误，请联系管理员');
    } else {
      notification.error({
        duration: 0,
        message: '发生错误',
        description:
          (ctx.res.msg || '未知错误，请联系管理员') + '\r\n' + `track_id: ${ctx.res.trace_id}`,
      });
    }
    // console.error(ctx.res)
    throw ctx.res; // throw 之后，可以走到 errorHandler 中去
  }
  // 没出错，正常执行
});

export default request;

import React, { FC, useEffect, useState } from 'react';
import styles from './index.module.scss';
import ICCs from './assets/ICCs.png';
import ICC from './assets/ICC.jpg';
import AZs from './assets/AZs.png';
import AZ from './assets/AZ.jpg';
import ChimaeraFinanicals from './assets/ChimaeraFinanicals.png';
import ChimaeraFinanical from './assets/ChimaeraFinanical.jpg';
import CCBAs from './assets/CCBAs.png';
import CCBA from './assets/CCBA.jpg';
import Testionis from './assets/Testionis.png';
import Testioni from './assets/Testioni.jpg';
import Asias from './assets/Asias.png';
import Asia1 from './assets/Asia1.jpg';
import Asia2 from './assets/Asia2.jpg';
import closableIcon from './assets/closableIcon.png';
import DutyFrees from './assets/DutyFrees.png';
import DutyFree from './assets/DutyFree.jpg';
import { Button, Modal, Space } from 'antd';
import intl from 'react-intl-universal';
import { debounce } from 'lodash';
import logo from '../contactUs/assets/logo.png';
import Carousel, { Modal as ImagesModal, ModalGateway } from 'react-images';
import { useHistory } from 'react-router-dom';

export const HotArticles: FC = () => {
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentObjIndex, setCurrentObjIndex] = useState(0);
  const [imgIndex, setImgIndex] = useState(-1);
  const [screenSize, setScreenSize] = useState<number>(document.documentElement.clientWidth);
  const resize = async () => {
    const offsetWid = document.documentElement.clientWidth;
    setScreenSize(offsetWid);
  };
  window.addEventListener('resize', debounce(resize, 20));
  const history = useHistory();
  useEffect(() => {
    // 获取不到重新更新路由
    if (intl.get('AZ').length === 0) {
      history.push('/main/hot-articles');
    }
  }, []);

  const imgObj = {
    0: [DutyFree],
    1: [Testioni],
    2: [CCBA],
    3: [AZ],
    4: [ICC],
    5: [ChimaeraFinanical],
    6: [Asia1, Asia2],
  };

  const imgList = [DutyFrees, Testionis, CCBAs, AZs, ICCs, ChimaeraFinanicals, Asias];

  const openModal = (index: number) => {
    setCurrentIndex(index);
    setVisible(true);
  };

  const closable = () => {
    setVisible(false);
    setCurrentObjIndex(0);
  };

  return (
    <>
      {screenSize > 800 ? (
        <div className={styles.hot}>
          <div className={styles.title}>{intl.get('recommendationLetter')}</div>
          <div className={styles.imgList}>
            <Space size={[35, 35]} wrap>
              {imgList.map((item, index) => (
                <div key={index} className={styles.imgDIv}>
                  <img alt="" src={item} />
                  <Button type="primary" className={styles.imgBtn} onClick={() => openModal(index)}>
                    {intl.get('viewBtn')}
                  </Button>
                </div>
              ))}
            </Space>
          </div>

          <Modal
            destroyOnClose
            visible={visible}
            footer={false}
            width="100%"
            // onCancel={() => setVisible(false)}
            className={styles.hotModal}
            style={{ top: 0 }}
            closable={false}
            getContainer={false}
          >
            <div className={styles.modalDiv}>
              <div>
                <img
                  alt=""
                  src={imgObj[currentIndex][currentObjIndex]}
                  className={styles.mainImg}
                />
              </div>
              <div>
                {imgObj[currentIndex].length > 1 &&
                  imgObj[currentIndex].map((item: string, index: number) => (
                    <div
                      className={styles.thumbnail}
                      key={item}
                      onClick={() => setCurrentObjIndex(index)}
                    >
                      <div className={styles.thumbnailDiv}>
                        <img alt="" src={item} />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <img alt="" src={closableIcon} className={styles.closableIcon} onClick={closable} />
          </Modal>
        </div>
      ) : (
        <div className={styles.rLettersMobile}>
          <div className={styles.rLettersBackImg}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
                width: 200,
                marginLeft: '10%',
                paddingTop: 30,
                paddingBottom: 30,
              }}
            >
              <img src={logo} alt={''} />
              <h1 style={{ color: '#ffffff' }}>{intl.get('recommendationLetter')}</h1>
            </div>
          </div>
          <div className={styles.imgListM}>
            <Space size={[0, 35]} wrap>
              {imgList.map((item, index) => (
                <div key={index} className={styles.imgDIvM}>
                  <img alt="" src={item} onClick={() => setImgIndex(index)} />
                </div>
              ))}
            </Space>
          </div>
          <ModalGateway>
            {imgIndex > -1 ? (
              <ImagesModal onClose={() => setImgIndex(-1)}>
                <Carousel views={imgObj[imgIndex].map((item: string) => ({ source: item }))} />
              </ImagesModal>
            ) : null}
          </ModalGateway>
        </div>
      )}
    </>
  );
};

export default HotArticles;

import React, { useEffect, useState, FC } from 'react';
import { Layout } from 'antd';
import styles from './index.module.scss';
import { BasicHeader } from './Header';
import { BasicFooter } from './Footer';
import { BasicAnchor } from './anchor';
import { debounce } from 'lodash';

export const BasicLayout: FC = ({ children }) => {
  const [isTop, setIsTop] = useState(false);

  const [maskStyle, setMaskStyle] = useState(false);

  const mobileMask = (v: boolean) => {
    setMaskStyle(v);
  }

  const scroll = async () => {
    const isBottom =
      document.documentElement.scrollTop + 10 >= document.documentElement.clientHeight;
    if (isTop !== isBottom) {
      await setIsTop(isBottom);
    }
  };

  window.addEventListener('scroll', debounce(scroll, 20));

  return (
    <>
      {document.documentElement.clientWidth > 800 ? (
        <Layout className={styles.layout}>
          <div className={styles.content}>
            <BasicHeader />
            {children}
          </div>
          <BasicFooter />
        </Layout>
      ) : (
        <Layout className={maskStyle ? `${styles.layoutMobileMask} ${styles.layoutMobile}` : styles.layoutMobile}>
          <BasicAnchor isTop mobileMask={mobileMask} />
          <div className={maskStyle ? styles.layoutMobileInfo : styles.contentMobile}>
            <BasicHeader />
            {children}
          </div>
          <BasicFooter />
        </Layout>
      )}
    </>
  );
};

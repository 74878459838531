import styles from './index.module.scss';
import { Button, Tooltip } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import { emit } from '../utils/emit';
import { Enum } from '../interfaces/enum';
import { debounce } from 'lodash';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';

interface Iprops {
  isTop: boolean;
  mobileMask?: (v: boolean) => void;
}

export const BasicAnchor: FC<Iprops> = ({ isTop, mobileMask }) => {
  const history = useHistory();
  const [anchorIndex, setAnchorIndex] = useState(0);
  const locale = intl.getInitOptions()?.currentLocale || Enum.中文;
  const [screenSize, setScreenSize] = useState<number>(document.documentElement.clientWidth);
  const [visibleTooltip, setVisibleTooltip] = useState(false);

  const resize = async () => {
    const offsetWid = document.documentElement.clientWidth;
    setScreenSize(offsetWid);
  };
  window.addEventListener('resize', debounce(resize, 20));

  const initialize = [
    '/about-us',
    '/main/renovation-design',
    '/main/hot-articles',
    '/main/contact-us',
  ];

  useEffect(() => {
    setAnchorIndex(initialize.indexOf(location.pathname));
  }, []);

  const buttonTextArr = [
    {
      link: initialize[0],
      text: (
        <Tooltip
          placement="bottom"
          title={
            <div className={styles.tooltipTitle}>
              <div onClick={() => (window.location.hash = 'what')}>What we make</div>
              <div onClick={() => (window.location.hash = 'who')}>Who are we</div>
              <div onClick={() => (window.location.hash = 'when')}>When we began</div>
              <div onClick={() => (window.location.hash = 'where')}>Where we live</div>
              <div onClick={() => (window.location.hash = 'how')}>How we do</div>
            </div>
          }
          overlayClassName={styles.tooltip}
        >
          <div className={styles.tooltipDiv}>
            <div>{intl.get('aboutUs')}</div>
          </div>
        </Tooltip>
      ),
    },
    {
      link: initialize[1],
      text: intl.get('interiorDesign'),
    },
    {
      link: initialize[2],
      text: intl.get('recommendationLetter'),
    },
    {
      link: initialize[3],
      text: intl.get('contactUs'),
    },
  ];

  const buttonTextArrM = [
    {
      link: initialize[0],
      text: intl.get('aboutUs'),
    },
    {
      link: initialize[1],
      text: intl.get('interiorDesign'),
    },
    {
      link: initialize[2],
      text: intl.get('recommendationLetter'),
    },
    {
      link: initialize[3],
      text: intl.get('contactUs'),
    },
  ];

  const anchorClick = (path: string, index: number) => {
    mobileMask && mobileMask(!visibleTooltip);
    setVisibleTooltip(!visibleTooltip);
    setAnchorIndex(index);
    history.push(path);
  };

  return (
    <>
      {screenSize > 800 ? (
        <div
          className={
            isTop
              ? `${styles.anchor} ${styles.anchorTop}`
              : `${styles.anchor} ${styles.anchorBottom}`
          }
          style={{ fontWeight: 600 }}
        >
          {buttonTextArr.map((item, index) => (
            <Button
              type="link"
              onClick={() => anchorClick(item.link, index)}
              className={styles.navHover}
              key={index}
              style={
                index === anchorIndex
                  ? { opacity: 1, borderBottom: '2px solid rgba(216, 216, 216, 0.7)' }
                  : { opacity: 0.5 }
              }
            >
              {item.text}
            </Button>
          ))}
          <Button
            type="link"
            className={styles.navHover}
            onClick={() => {
              // 发送消息
              emit.emit('change_language', locale === Enum.中文 ? Enum.英文 : Enum.中文);
            }}
          >
            {locale === Enum.中文 ? 'ENG' : '中文'}
          </Button>
        </div>
      ) : (
        <Tooltip
          placement="bottom"
          title={
            <div className={styles.tooltipTitleM}>
              {buttonTextArrM.map((item, index) => (
                <div onClick={() => anchorClick(item.link, index)} key={index}>
                  {item.text}
                </div>
              ))}
              <div
                onClick={() => {
                  emit.emit('change_language', locale === Enum.中文 ? Enum.英文 : Enum.中文);
                  setVisibleTooltip(!visibleTooltip);
                  mobileMask && mobileMask(!visibleTooltip);
                }}
              >
                {locale === Enum.中文 ? 'ENG' : '中文'}
              </div>
            </div>
          }
          visible={visibleTooltip}
          overlayClassName={`${styles.tooltip} ${styles.tooltipM}`}
        >
          <div
            className={visibleTooltip ? styles.mobileNavi : styles.menuOutlined}
            onClick={() => {
              setVisibleTooltip(!visibleTooltip);
              mobileMask && mobileMask(!visibleTooltip);
            }}
          >
            {visibleTooltip ? <CloseOutlined /> : <MenuOutlined />}
          </div>
        </Tooltip>
      )}
    </>
  );
};
